import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import {
  translationsFactory,
  TranslationsContext,
  Letters,
  Translation,
  fallbackAlphabet,
  useTranslations,
} from 'settings/i18n';
import useLinkCreator from 'hooks/useLinkCreator';
import { useIsRtl } from 'hooks/useCurrentLocale';
import EducationNavigation from 'components/EducationNavigation';
import Footer from 'components/Footer';
import SEO, { SEOMeta } from 'components/SEO/SEO';
import Breadcrumbs from 'components/Breadcrumbs';
import { EducationHeading } from 'components/EducationTile/EducationTile';
import Heading from 'components/Heading/Heading';
import SocialShareBar from 'components/SocialShareBar';
import Text from 'components/Text/Text';
import whatIsCryptocurrency from 'images/what-is-cryptocurrency.svg';
import howToChooseWallet from 'images/how-to-use-a-wallet.svg';
import whatAreCryptocurrencyExchanges from 'images/what-are-cryptocurrency-exchanges.svg';
import whatIsHodling from 'images/what-is-hodling.svg';
import whatAreAltcoins from 'images/what-are-altcoins.svg';
import nft from 'images/nft.svg';
import coinsAndTokens from 'images/coins-and-tokens.svg';
import tokenGovernance from 'images/token-governance.svg';
import shoppingWithCrypto from 'images/shopping-with-crypto.svg';
import {
  Main,
  Wrapper,
  Trapezoid,
  TrapezoidWrapper,
  Line,
  Excerpt,
  CryptocurrencyHeading,
  ActionButtons,
  ActionButton,
  Image,
  RelativeContainer,
  ReversedTrapezoid,
  ReversedLine,
  ReversedImage,
  ReversedExcerpt,
  ThirdSection,
  EducationVideoModal,
  OrnamentTrapezoid,
  PdfActionButton,
} from 'components/EducationSection/EducationSection';
import EducationGlossary from 'components/EducationGlossary/EducationGlossary';
import ClientOnly from 'components/ClientOnly/ClientOnly';
import useResponsivePdfLink, { PdfLinks } from 'hooks/useResponsivePdfLink';
import extractPdfUrlsFactory from 'settings/extract-pdf-urls';
import menuLinks from 'settings/menuLinks';

const EducationCryptocurrencyMain = styled(Main)`
  overflow: hidden;
`;

const EducationCryptocurrencyProviders = ({
  data,
}: {
  data: {
    translations: Translation;
    letters: Letters;
    education: Translation;
    homepage: Translation;
    metadata: SEOMeta;
  };
}) => {
  const t = translationsFactory({
    wpPage: {
      id: 'translations',
      translation: {
        ...data?.translations?.translation,
        ...data?.education?.translation,
        ...data?.homepage?.translation,
      },
    },
  });

  const extractPdfUrls = extractPdfUrlsFactory(data);
  const letters = data?.letters?.translation.letters || fallbackAlphabet;

  return (
    <TranslationsContext.Provider value={t}>
      <SEO
        metadata={data.metadata || undefined}
        url={menuLinks.cryptocurrency}
      />
      <ClientOnly>
        <SocialShareBar pageTitle={data?.metadata?.seo?.title} />
        <EducationNavigation />
        <EducationCryptocurrency
          letters={letters}
          pdfs={{
            cryptocurrency: extractPdfUrls('whatAreCryptocurrencies', {
              fallbackDesktop: 'cryptocurrencies-desktop_en',
              fallbackMobile: 'cryptocurrencies-mobile_en',
            }),
            wallets: extractPdfUrls('whatAreWallets', {
              fallbackDesktop: 'wallets-desktop_en',
              fallbackMobile: 'wallets-mobile_en',
            }),
            exchanges: extractPdfUrls('whatAreExchanges', {
              fallbackDesktop: 'exchanges-desktop_en',
              fallbackMobile: 'exchanges-mobile_en',
            }),
            hodling: extractPdfUrls('whatIsHodling', {
              fallbackDesktop: 'hodling-desktop_en',
              fallbackMobile: 'hodling-mobile_en',
            }),
            altcoins: extractPdfUrls('whatAreAltcoins', {
              fallbackDesktop: 'altcoins-desktop_en',
              fallbackMobile: 'altcoins-mobile_en',
            }),
            coinsAndTokens: extractPdfUrls('coinsAndTokens', {
              fallbackDesktop: 'coins-vs-tokens-desktop_en',
              fallbackMobile: 'coins-vs-tokens-mobile_en',
            }),
            nft: extractPdfUrls('nft', {
              fallbackDesktop: 'nft-desktop_en',
              fallbackMobile: 'nft-mobile_en',
            }),
            tokenGovernance: extractPdfUrls('tokenGovernance', {
              fallbackDesktop: 'token-governance-desktop_en',
              fallbackMobile: 'token-governance-mobile_en',
            }),
            shoppingWithCrypto: extractPdfUrls('shoppingWithCrypto', {
              fallbackDesktop: 'shopping-with-crypto-desktop_en',
              fallbackMobile: 'shopping-with-crypto-mobile_en',
            }),
          }}
        />
        <Footer />
      </ClientOnly>
    </TranslationsContext.Provider>
  );
};

const EducationCryptocurrency = ({
  letters,
  pdfs,
}: {
  letters: Letters['translation']['letters'];
  pdfs: {
    cryptocurrency: PdfLinks;
    wallets: PdfLinks;
    exchanges: PdfLinks;
    hodling: PdfLinks;
    altcoins: PdfLinks;
    coinsAndTokens: PdfLinks;
    nft: PdfLinks;
    tokenGovernance: PdfLinks;
    shoppingWithCrypto: PdfLinks;
  };
}) => {
  const t = useTranslations();
  const createLink = useLinkCreator();
  const isRtl = useIsRtl();

  const cryptocurrencyPdfLink = useResponsivePdfLink(pdfs.cryptocurrency);
  const walletsPdfLink = useResponsivePdfLink(pdfs.wallets);
  const exchangesPdfLink = useResponsivePdfLink(pdfs.exchanges);
  const hodlingPdfLink = useResponsivePdfLink(pdfs.hodling);
  const altcoinsPdfLink = useResponsivePdfLink(pdfs.altcoins);
  const coinsAndTokensPdfLink = useResponsivePdfLink(pdfs.coinsAndTokens);
  const nftPdfLink = useResponsivePdfLink(pdfs.nft);
  const tokenGovernancePdfLink = useResponsivePdfLink(pdfs.tokenGovernance);
  const shoppingWithCryptoPdfLink = useResponsivePdfLink(
    pdfs.shoppingWithCrypto,
  );

  return (
    <EducationCryptocurrencyMain>
      <Wrapper>
        <EducationHeading
          isRtl={isRtl}
          size="heading"
          color="dark"
          weight="medium"
          letterSpacing="tight">
          {t('cryptocurrency')}
        </EducationHeading>

        <Breadcrumbs
          links={[
            { to: createLink(menuLinks.home), name: t('home') },
            { to: createLink(menuLinks.education), name: t('education') },
            {
              to: createLink(menuLinks.cryptocurrency),
              name: t('cryptocurrency'),
            },
          ]}
        />
      </Wrapper>

      <RelativeContainer isRtl={isRtl}>
        <Trapezoid>
          <Line>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal">
              1
            </Heading>
          </Line>
          <TrapezoidWrapper>
            <Excerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('whatIsCryptocurrency')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('whatIsCryptocurrencyContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={cryptocurrencyPdfLink} />
                <EducationVideoModal src="https://www.youtube.com/embed/lPRh-OgOOWk">
                  {t('video')}
                </EducationVideoModal>
                <ActionButton to={createLink(menuLinks.quizCryptocurrency)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </Excerpt>
            <Image src={whatIsCryptocurrency} alt={t('whatIsCryptocurrency')} />
          </TrapezoidWrapper>
        </Trapezoid>

        <ReversedTrapezoid>
          <ReversedLine style={{ marginLeft: '0' }}>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal"
              style={{ background: '#e9f6ff' }}>
              2
            </Heading>
          </ReversedLine>
          <TrapezoidWrapper>
            <ReversedImage
              src={howToChooseWallet}
              alt={t('howToChooseWallet')}
            />
            <ReversedExcerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('howToChooseWallet')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('howToChooseWalletContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={walletsPdfLink} />
                <ActionButton to={createLink(menuLinks.quizWallet)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </ReversedExcerpt>
          </TrapezoidWrapper>
        </ReversedTrapezoid>

        <ThirdSection>
          <Line>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal">
              3
            </Heading>
          </Line>
          <TrapezoidWrapper>
            <Excerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('whatAreCryptocurrencyExchanges')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('whatAreCryptocurrencyExchangesContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={exchangesPdfLink} />

                <ActionButton to={createLink(menuLinks.quizTrading)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </Excerpt>
            <Image
              src={whatAreCryptocurrencyExchanges}
              alt={t('whatAreCryptocurrencyExchanges')}
            />
          </TrapezoidWrapper>
        </ThirdSection>

        <ReversedTrapezoid>
          <ReversedLine style={{ marginLeft: '0' }}>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal"
              style={{ background: '#e9f6ff' }}>
              4
            </Heading>
          </ReversedLine>
          <TrapezoidWrapper>
            <ReversedImage
              src={whatIsHodling}
              alt={t('whatIsHodling')}
              style={{ transform: 'scale(1.6)' }}
            />
            <ReversedExcerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('whatIsHodling')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('whatIsHodlingContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={hodlingPdfLink} />
              </ActionButtons>
            </ReversedExcerpt>
          </TrapezoidWrapper>
        </ReversedTrapezoid>

        <ThirdSection>
          <Line>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal">
              5
            </Heading>
          </Line>
          <TrapezoidWrapper>
            <Excerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('whatAreAltcoins')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('whatAreAltcoinsContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={altcoinsPdfLink} />
                <ActionButton to={createLink(menuLinks.quizAltcoins)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </Excerpt>
            <Image src={whatAreAltcoins} alt={t('whatAreAltcoins')} />
          </TrapezoidWrapper>
        </ThirdSection>

        <ReversedTrapezoid>
          <ReversedLine style={{ marginLeft: '0' }}>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal"
              style={{ background: '#e9f6ff' }}>
              6
            </Heading>
          </ReversedLine>
          <TrapezoidWrapper>
            <ReversedImage src={coinsAndTokens} alt={t('coinsAndTokens')} />
            <ReversedExcerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('coinsAndTokens')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('coinsAndTokensContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={coinsAndTokensPdfLink} />
                <ActionButton to={createLink(menuLinks.quizCoinsVsTokens)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </ReversedExcerpt>
          </TrapezoidWrapper>
        </ReversedTrapezoid>

        <ThirdSection>
          <Line>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal">
              7
            </Heading>
          </Line>
          <TrapezoidWrapper>
            <Excerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('nft')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('nftContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={nftPdfLink} />
                <EducationVideoModal src={t('nftYtVideoLink')}>
                  {t('video')}
                </EducationVideoModal>
                <ActionButton to={createLink(menuLinks.quizNft)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </Excerpt>
            <Image
              src={nft}
              alt={t('nft')}
              style={{ transform: 'scale(0.6)' }}
            />
          </TrapezoidWrapper>
        </ThirdSection>

        <ReversedTrapezoid>
          <ReversedLine style={{ marginLeft: '0' }}>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal"
              style={{ background: '#e9f6ff' }}>
              8
            </Heading>
          </ReversedLine>
          <TrapezoidWrapper>
            <ReversedImage src={tokenGovernance} alt={t('tokenGovernance')} />
            <ReversedExcerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('tokenGovernance')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('tokenGovernanceContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={tokenGovernancePdfLink} />
                <ActionButton to={createLink(menuLinks.quizTokenGovernance)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </ReversedExcerpt>
          </TrapezoidWrapper>
        </ReversedTrapezoid>

        <ThirdSection>
          <Line>
            <Heading
              isRtl={isRtl}
              as="strong"
              size="heading"
              color="dark"
              weight="medium"
              letterSpacing="normal">
              9
            </Heading>
          </Line>
          <TrapezoidWrapper>
            <Excerpt>
              <CryptocurrencyHeading
                isRtl={isRtl}
                as="h2"
                size="title"
                color="dark"
                weight="medium"
                letterSpacing="tight">
                {t('shoppingWithCrypto')}
              </CryptocurrencyHeading>
              <Text
                isRtl={isRtl}
                style={{ marginTop: '6px', paddingRight: '6px' }}>
                {t('shoppingWithCryptoContent')}
              </Text>
              <ActionButtons>
                <PdfActionButton pdfLink={shoppingWithCryptoPdfLink} />
                <ActionButton to={createLink(menuLinks.quizShoppingWithCrypto)}>
                  {t('quiz')}
                </ActionButton>
              </ActionButtons>
            </Excerpt>
            <Image src={shoppingWithCrypto} alt={t('shoppingWithCrypto')} />
          </TrapezoidWrapper>
        </ThirdSection>

        <OrnamentTrapezoid />
      </RelativeContainer>

      <EducationGlossary letters={letters} />
    </EducationCryptocurrencyMain>
  );
};

export default EducationCryptocurrencyProviders;

export const query = graphql`
  query($locale: String!) {
    translations: wpPage(
      title: { in: "glossary" }
      language: { locale: { eq: $locale } }
    ) {
      ...GlossaryTranslation
    }

    letters: wpPage(
      title: { in: "alphabet" }
      language: { locale: { eq: $locale } }
    ) {
      translation {
        letters
      }
    }

    metadata: wpPage(
      slug: { regex: "/\\b(\\w*education-cryptocurrency\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...MetaData
    }

    education: wpPage(
      slug: { regex: "/\\b(\\w*education\\w*)+(-\\d+)?\\b$/" }
      language: { locale: { eq: $locale } }
    ) {
      translation {
        education
        home
        cryptocurrency
        whatIsCryptocurrency
        whatIsCryptocurrencyContent
        howToChooseWallet
        howToChooseWalletContent
        whatAreCryptocurrencyExchanges
        whatAreCryptocurrencyExchangesContent
        whatIsHodling
        whatIsHodlingContent
        whatAreAltcoins
        whatAreAltcoinsContent
        coinsAndTokens
        coinsAndTokensContent
        pdf
        video
        quiz
        glossary
        whatAreCryptocurrenciesPdfDesktop {
          slug
        }
        whatAreCryptocurrenciesPdfMobile {
          slug
        }
        whatAreWalletsPdfDesktop {
          slug
        }
        whatAreWalletsPdfMobile {
          slug
        }
        whatAreExchangesPdfDesktop {
          slug
        }
        whatAreExchangesPdfMobile {
          slug
        }
        whatIsHodlingPdfMobile {
          slug
        }
        whatIsHodlingPdfDesktop {
          slug
        }
        whatAreAltcoinsPdfMobile {
          slug
        }
        whatAreAltcoinsPdfDesktop {
          slug
        }
        coinsAndTokensPdfMobile {
          slug
        }
        coinsAndTokensPdfDesktop {
          slug
        }

        nft
        nftContent
        nftYtVideoLink
        nftPdfMobile {
          slug
        }
        nftPdfDesktop {
          slug
        }

        shoppingWithCrypto
        shoppingWithCryptoContent
        shoppingWithCryptoPdfDesktop {
          slug
        }
        shoppingWithCryptoPdfMobile {
          slug
        }

        tokenGovernance
        tokenGovernanceContent
        tokenGovernancePdfDesktop {
          slug
        }
        tokenGovernancePdfMobile {
          slug
        }
      }
    }
    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
      ...FooterTranslation
      ...AboutTranslation
    }
  }
`;
